import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import Collapse from '@material-ui/core/Collapse'
import Paper from '@material-ui/core/Paper'; 
import Typography from '@material-ui/core/Typography';
import { Select, MenuItem } from '@material-ui/core';
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Button, ButtonGroup } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import DescriptionIcon from '@material-ui/icons/Description';
import ReplayIcon from '@material-ui/icons/Replay';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Snackbar from '@material-ui/core/Snackbar'
import Box from '@material-ui/core/Box'
import { dashboardUrl } from '../utils';
import { 
    createTaskQueryObject, 
    languages, 
    fetchQueueList, 
    samplingRates, 
    retryTask, 
    fetchTaskListAndPrepare 
} from '../api';
import ErrorHandler from '../ErrorHandler';
import RefreshIcon from '@material-ui/icons/Refresh';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 160,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const displayDuration = (duration) => {
    duration = duration / 1000;
    let hours = Math.floor(duration / 3600);
    let minutes = Math.floor((duration - hours * 3600) / 60);
    let seconds = Math.floor(duration - (hours * 3600 + minutes * 60));
    let durString = '';
    if(hours > 0) durString += (hours + ' h ');
    if(minutes > 0) durString += (minutes + ' m ');
    if(seconds > 0) durString += (seconds + ' s ');
    return durString;
}

export const TaskTable = (props) => {
    const classes = useStyles();

    const commonCols = {'task_id': true, 'ref_id': false, 'create_dt': true, 'mod_ts': true, 'lang': true, 'queue': true, 'sampling': true, 'retry_cnt': false};
    const cols = {...commonCols}
    for(let col of Object.keys(props.optionalCols)) {
        cols[col] = props.optionalCols[col]
    }

    const [tasks, setTasks] = useState([]);
    const [limit, setLimit] = useState(25);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [sortOrder, setSortOrder] = useState('task_id_desc');
    const [queues, setQueues] = useState([]);
    const [queueVal, setQueueVal] = useState("");
    const [langVal, setLangVal] = useState("");
    const [samplingVal, setSamplingVal] = useState("");
    const [refVal, setRefVal] = useState("");
    const [createFromVal, setCreateFromVal] = useState("");
    const [createToVal, setCreateToVal] = useState("");
    const [retrySnackOpen, setRetrySnackOpen] = useState([false, ""]);
    const [refresh, setRefresh] = useState(false);


    const updateTable = (
        sortOrder,
        limit, 
        page, 
        props,
        queueVal,
        langVal,
        samplingVal,
        refVal,
        createFromVal,
        createToVal
    ) => {
        const queryObject = createTaskQueryObject(
                                sortOrder,
                                limit, 
                                page, 
                                props.queryFilters,
                                queueVal,
                                langVal,
                                samplingVal,
                                refVal,
                                createFromVal,
                                createToVal
                            )
        fetchTaskListAndPrepare(queryObject).then((obj) => {
            setTotal(obj.meta.total);
            setTasks(obj.tasks);
        });
        fetchQueueList().then((queues) => {
            setQueues(queues);
        });
    }

    useEffect(() => {
        let autoUpdates = null;
        if(props.automaticUpdates) {
            updateTable(sortOrder,
                limit, 
                page, 
                props,
                queueVal,
                langVal,
                samplingVal,
                refVal,
                createFromVal,
                createToVal
            );
            autoUpdates = setInterval(() => {
                updateTable(sortOrder,
                    limit, 
                    page,  
                    props,
                    queueVal,
                    langVal,
                    samplingVal,
                    refVal,
                    createFromVal,
                    createToVal
                );
            }, 10000)
        } else {
            updateTable(sortOrder,
                limit, 
                page, 
                props,
                queueVal,
                langVal,
                samplingVal,
                refVal,
                createFromVal,
                createToVal
            );
        }

        if(props.automaticUpdates) {
            return () => clearInterval(autoUpdates);
        }

    }, [sortOrder,
        limit, 
        page, 
        total, 
        props,
        queueVal,
        langVal,
        samplingVal,
        refVal,
        createFromVal,
        createToVal,
        retrySnackOpen,
        refresh]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage+1);
    };

    const handleChangeRowsPerPage = (event) => {
        setLimit(parseInt(event.target.value, 10));
        setPage(1);
    }

    const handleSortOrderChange = () => {
        setSortOrder(sortOrder === 'task_id_desc' ? 'task_id_asc' : 'task_id_desc');
    }

    const handleCreateFromVal = (event) => {
        setCreateFromVal(event.target.value);
    }

    const handleCreateToVal = (event) => {
        setCreateToVal(event.target.value);
    }

    const handleReset = () => {
        setQueueVal("");
        setLangVal("");
        setSamplingVal("");
        setRefVal("");
        setCreateFromVal("")
        setCreateToVal("");
    }

    const handleRetry = (task_id) => {
        retryTask(task_id)
        .then((task_id) => {
            setRetrySnackOpen([true, "Submitted Task "+task_id+" for retrying"]);
        })
        .catch((err) => {
            setRetrySnackOpen([true, "Failed to submit Task "+task_id+" for retrying"]);
        })
    }

    const handleRetrySnackClose = () => {
        setRetrySnackOpen([false, ""]);
    }

    const handleExport = () => {
        (async () => {
            let columnHeaders = {
                'task_id': 'Task ID',
                'ref_id': 'Reference ID',
                'create_dt': 'Date Created',
                'mod_ts': 'Last Updated',
                'proc_time': 'Processing Time',
                'lang': 'Language',
                'queue': 'Queue',
                'sampling': 'Sampling Rate',
                'worker': 'Worker',
                'progress': 'Progress',
                'err_code': 'Error Code',
                'status': 'Status',
                'dl_transcript': 'Recording,Transcript',
                'retry_cnt': 'Retry Count'
            }

            let exportCols = Object.keys(cols).filter((col) => col in columnHeaders);

            let columnData = {
                'task_id': (task) => task.task_id,
                'ref_id': (task) => task.ref_id,
                'create_dt': (task) => task.create_dt.toUTCString().split(", ")[1],
                'mod_ts': (task) => task.mod_ts.toUTCString().split(", ")[1],
                'proc_time': (task) => task.proc_time === null ? null : displayDuration(task.proc_time),
                'lang': (task) => task.lang,
                'queue': (task) => task.queue,
                'sampling': (task) => task.data.sampling,
                'worker': (task) => task.worker,
                'progress': (task) => task.progress,
                'err_code': (task) => task.err_code,
                'status': (task) => task.status,
                'dl_transcript': (task) => task.data["cloud-link"]+","+task.result["cloud-link"],
                'retry_cnt': (task) => task.retry_cnt
            }

            // to fetch all tasks
            const totalTasksNumQueryObject = createTaskQueryObject(
                                                "task_id_desc",
                                                0, 
                                                1,
                                                props.queryFilters,
                                                queueVal,
                                                langVal,
                                                samplingVal,
                                                refVal,
                                                createFromVal,
                                                createToVal
                                            );
            // fetch total number of tasks
            const { meta } = await fetchTaskListAndPrepare(totalTasksNumQueryObject);
            const totalTasksNum = meta.total;
            console.log(totalTasksNum);
            const exportTasksQueryObject = createTaskQueryObject(
                                                "task_id_desc",
                                                totalTasksNum, 
                                                1,
                                                props.queryFilters,
                                                queueVal,
                                                langVal,
                                                samplingVal,
                                                refVal,
                                                createFromVal,
                                                createToVal
                                            );
            
            const obj = await fetchTaskListAndPrepare(exportTasksQueryObject);
            const exportTasks = obj.tasks;

            let header = ""
            for(let col of exportCols) {
                header += columnHeaders[col] + ",";
            }
            header += "\n";
            let csv = header;
            exportTasks.forEach((task) => {
                for(let col of exportCols) {
                    csv += columnData[col](task)  + ",";
                }
                csv += "\n";
            });
            
            let csvData = new Blob([csv], {type: 'text/csv'});
            let csvUrl = URL.createObjectURL(csvData);

            let hiddenElement = document.createElement('a');
            hiddenElement.href = csvUrl;
            hiddenElement.target = '_blank';
            let filename = props.title.toLowerCase().split(" ").join("-") + "-" + new Date().toISOString();
            hiddenElement.download = filename + ".csv";
            hiddenElement.click();
        }
    )();
}

    const headRenders = { // the way to render every table header
        'task_id': <TableSortLabel active direction={sortOrder === 'task_id_desc' ? 'desc' : 'asc'} onClick={handleSortOrderChange}>Task ID</TableSortLabel>,
        'ref_id': ('Reference ID'),
        'create_dt': ('Date Created'),
        'mod_ts': ('Last Updated'),
        'proc_time': ('Processing Time'),
        'lang': ('Language'),
        'queue': ('Queue'),
        'sampling': ('Sampling Rate'),
        'worker': ('Worker'),
        'progress': ('Progress'),
        'err_code': ('Error Code'),
        'status': ('Status'),
        'dl_transcript': ('Recording & Transcript'),
        'retry': ('Retry'),
        'retry_cnt': ('Retry Count')
    };

    const renderDownloadTranscript = (recLink, trLink) => {
        return (
            <div style={{display: 'flex'}}>
                <a href={recLink} target="_blank" rel="noopener noreferrer">
                    <IconButton aria-label="download-recording"><RecordVoiceOverIcon /></IconButton>
                </a>
                <a href={trLink}>
                    <IconButton aria-label="download-transcript"><DescriptionIcon /></IconButton>
                </a>
            </div>
        );
    }

    const bodyRenders = { // the way to render data in every column
        'task_id': (task) => (<a href={dashboardUrl + "task/"+task.task_id} style={{textDecoration: "none"}} target="_blank" rel="noopener noreferrer">
                                <Button>{task.task_id}</Button>
                            </a>),
        'ref_id': (task) => task.ref_id,
        'create_dt': (task) => task.create_dt.toUTCString(),
        'mod_ts': (task) => task.mod_ts.toUTCString(),
        'proc_time': (task) => task.proc_time === null ? null : displayDuration(task.proc_time),
        'lang': (task) => task.lang,
        'queue': (task) => task.queue,
        'sampling': (task) => task.data.sampling,
        'worker': (task) => task.worker,
        'progress': (task) => task.progress,
        'err_code': (task) => task.err_code.split("_").join(" "),
        'status': (task) => task.status,
        'dl_transcript': (task) => renderDownloadTranscript(task.data['cloud-link'], task.result['cloud-link']),
        'retry': (task) => (<IconButton aria-label="Retry" onClick={() => handleRetry(task.task_id)}><ReplayIcon /></IconButton>),
        'retry_cnt': (task) => task.retry_cnt
    };

    return (
        <React.Fragment>
            <Typography variant='h5'>
                <strong>{props.title}</strong><IconButton onClick={() => setRefresh(!refresh)}><RefreshIcon /></IconButton>
            </Typography>
            <br />
            <ErrorHandler>
                <div>
                    <Paper id='filters'>
                        <div style={{padding: "10px 20px 10px 20px"}}>
                            <Typography>
                                <strong>Filters</strong>
                            </Typography>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="queue-select-label">Queue</InputLabel>
                                <Select
                                    labelId="queue-select-label"
                                    id="queue-select"
                                    value={queueVal}
                                    onChange={(event) => setQueueVal(event.target.value)}
                                    >
                                    <MenuItem value=""><em>All</em></MenuItem>
                                    {queues.map((queue) => <MenuItem value={queue} key={queue}>{queue}</MenuItem>)}
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="lang-select-label">Language</InputLabel>
                                <Select
                                    labelId="lang-select-label"
                                    id="lang-select"
                                    value={langVal}
                                    onChange={(event) => setLangVal(event.target.value)}
                                    >
                                    <MenuItem value=""><em>All</em></MenuItem>
                                    {languages.map((language) => <MenuItem value={language} key={language}>{language}</MenuItem>)}
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="sampling-select-label">Sampling Rate</InputLabel>
                                <Select
                                    labelId="sampling-select-label"
                                    id="sampling-select"
                                    value={samplingVal}
                                    onChange={(event) => setSamplingVal(event.target.value)}
                                    >
                                    <MenuItem value=""><em>All</em></MenuItem>
                                    {samplingRates.map((rate) => <MenuItem value={rate} key={rate}>{rate}</MenuItem>)}
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField id="reference-id" label="Reference ID" value={refVal} onChange={(event) => setRefVal(event.target.value)}/>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField 
                                    type="date" 
                                    label="Date Created-From" 
                                    value={createFromVal} 
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{max: createToVal}}
                                    onChange={handleCreateFromVal}
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField 
                                    type="date" 
                                    label="Date Created-To" 
                                    value={createToVal} 
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{min: createFromVal}}
                                    onChange={handleCreateToVal}
                                />
                            </FormControl>
                            <br />
                            <FormControl className={classes.formControl}>
                                <ButtonGroup>
                                    <Button onClick={handleReset}>Reset</Button>
                                    <Button onClick={handleExport}>Export</Button>
                                </ButtonGroup>
                            </FormControl>
                        </div>
                    </Paper>
                </div>
                <br />
                <Paper>
                    <TableContainer style={{maxHeight: '70vh'}}>
                    <Table style={{minWidth: 700}} stickyHeader size="small" aria-label="task table">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                {Object.keys(cols).filter((col) => cols[col]).map((col) => {
                                    return <TableCell key={col}><strong>{headRenders[col]}</strong></TableCell>
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tasks.length > 0
                                ? tasks.map((task) => <CollapsibleRow key={task.task_id} task={task} cols={cols} bodyRenders={bodyRenders} headRenders={headRenders}/>)
                                :<TableRow>
                                    <TableCell align='center' colSpan={Object.keys(cols).length + 1}>
                                        <p style={{fontSize: 'large', margin: 0}}>No Tasks to display</p>
                                    </TableCell>
                                </TableRow>}
                        </TableBody>
                    </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={total}
                        rowsPerPage={limit}
                        page={page-1} // TablePagination's pages start at 0, API's start at 1
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
                <Snackbar
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    }}
                    open={retrySnackOpen[0]}
                    autoHideDuration={5000}
                    onClose={handleRetrySnackClose}
                    message={retrySnackOpen[1]}
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleRetrySnackClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                />
            </ErrorHandler>
        </React.Fragment>
    );
}

const CollapsibleRow = (props) => {
    const [showHidden, setShowHidden] = useState(false);

    return (
        <React.Fragment>
            <TableRow style={{borderBottom: 'unset'}}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setShowHidden(!showHidden)}>
                        {showHidden ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {Object.keys(props.cols).filter((col) => props.cols[col]).map((col => {
                    return <TableCell key={col}>{props.bodyRenders[col](props.task)}</TableCell>
                }))}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={Object.keys(props.cols).length + 1}>
                    <Collapse in={showHidden} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {Object.keys(props.cols).filter((col) => !props.cols[col]).map((col) => {
                                            return <TableCell key={col}><strong>{props.headRenders[col]}</strong></TableCell>
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        {Object.keys(props.cols).filter((col) => !props.cols[col]).map((col) => {
                                            return <TableCell key={col}>{props.bodyRenders[col](props.task)}</TableCell>
                                        })}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}