import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Overview } from './Overview/Overview';
import FailedTasks from './FailedTasks';
import SuccessfulTasks from './SuccessfulTasks';
import ProcessingTasks from './ProcessingTasks';
import PendingTasks from './PendingTasks';
import Task from './Task/Task';
import { Workers, TasksByWorker } from './Workers/Workers';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import UpdateIcon from '@material-ui/icons/Update';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import SubjectIcon from '@material-ui/icons/Subject';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import MemoryIcon from '@material-ui/icons/Memory';


const drawerWidth = 240;

const closeDrawerWidth = 1024;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const Dashboard = (props) => {
  const { history } = props;

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(window.innerWidth > closeDrawerWidth);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const drawerItems = [
    {
      text: "Overview",
      onClick: () => history.push('/'),
      icon: <SubjectIcon />
    },
    {
      text: "Failed Tasks",
      onClick: () => history.push('/fail'),
      icon: <ErrorOutlineIcon />
    },
    {
      text: "Successful Tasks",
      onClick: () => history.push('/success'),
      icon: <CheckCircleOutlineIcon />
    },
    {
      text: "Processing Tasks",
      onClick: () => history.push('/processing'),
      icon: <UpdateIcon />
    },
    {
      text: "Pending Tasks",
      onClick: () => history.push('/pending'),
      icon: <QueryBuilderIcon />
    },
    {
      text: "Task",
      onClick: () => history.push('/task'),
      icon: <InsertDriveFileIcon />
    },
    {
      text: "Workers",
      onClick: () => history.push('/workers'),
      icon: <MemoryIcon />
    }
  ]

  /* for single task page */
  const updateTaskUrl = (task_id) => {
    history.push('/task/'+task_id);
  }
  /* for single task page */

  React.useEffect(() => {
    window.addEventListener('resize', () => setOpen(window.innerWidth > closeDrawerWidth));

    return () => window.removeEventListener('resize', () => setOpen(window.innerWidth > closeDrawerWidth));
  });

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {drawerItems.map((obj, index) => (
            <ListItem button onClick={() => {obj.onClick(); if(window.innerWidth <= closeDrawerWidth) setOpen(false);}} key={index}>
              <ListItemIcon>{obj.icon}</ListItemIcon>
              <ListItemText primary={obj.text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Switch>
            <Route exact path="/">
                <Overview />
            </Route>
            <Route exact path="/fail">
                <FailedTasks />
            </Route>
            <Route exact path="/success">
                <SuccessfulTasks />
            </Route>
            <Route exact path="/processing">
                <ProcessingTasks />
            </Route>
            <Route exact path="/pending">
                <PendingTasks />
            </Route>
            <Route path="/task/:taskID?">
              <Task updateTaskUrl={updateTaskUrl} />
            </Route>
            <Route path="/workers">
              <React.Fragment>
                <Workers />
                <br />
                <Divider />
                <br />
                <TasksByWorker />
              </React.Fragment>
            </Route>
        </Switch>
      </main>
    </div>
  );
}

export default withRouter(Dashboard);
