import React from 'react'
import { TaskTable } from './TaskTable/TaskTable'

const PendingTasks = () => {
    return (
        <TaskTable 
            title={'Pending Tasks'} 
            optionalCols = {{}}
            queryFilters={{'status': 0}}
            automaticUpdates={false}
        />
    );
}

export default PendingTasks