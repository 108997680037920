import React from 'react'
import { TaskTable } from './TaskTable/TaskTable'

const FailedTasks = () => {
    return (
        <TaskTable 
            title={'Failed Tasks'} 
            optionalCols = {{'worker': false, 'proc_time': true, 'err_code': true, 'retry': true}}
            queryFilters={{'status': 3}}
            automaticUpdates={false}
        />
    );
}

export default FailedTasks