import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useParams } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import Box from '@material-ui/core/Box';
import { Card, CardContent } from '@material-ui/core';
import axios from 'axios';
import { taskControllerApiHost } from '../utils';
import ErrorHandler from '../ErrorHandler';
import RefreshIcon from '@material-ui/icons/Refresh';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    }
}));

const displayDuration = (duration) => {
    duration = duration / 1000;
    let hours = Math.floor(duration / 3600);
    let minutes = Math.floor((duration - hours * 3600) / 60);
    let seconds = Math.floor(duration - (hours * 3600 + minutes * 60));
    let durString = '';
    if(hours > 0) durString += (hours + ' h ');
    if(minutes > 0) durString += (minutes + ' m ');
    if(seconds > 0) durString += (seconds + ' s ');
    return durString;
}

const fetchLatestTaskID = async () => {
    let latestTaskFetch = await fetch(taskControllerApiHost + "tasks?_sort_key=task_id_desc&_limit=1");
    let latestTaskJSON = await latestTaskFetch.json();
    let latestTaskID = latestTaskJSON.tasks[0].task_id;
    return latestTaskID;
}

const fetchTask = async (task_id) => {
    let taskFetch = await fetch(taskControllerApiHost + "tasks/"+task_id);
    if(!taskFetch.ok) {
        throw new Error();
    }
    let { task } = await taskFetch.json();
    task.status = ["PENDING", "PROCESSING", "SUCCESSFUL", "FAILED"][task.status];
    task.create_dt = new Date(task.create_dt + 'Z'); // store Date object instead of string
    task.mod_ts = new Date(task.mod_ts + 'Z');
    task.process_dt = task.process_dt === null ? null : new Date(task.process_dt + 'Z');
    task.proc_time = task.process_dt === null ? null : task.mod_ts - task.process_dt; // store duration between mod_ts and process_dt in ms
    return task;
}

const getFields = (task) => {
    return ({ 
        task_id: {
            title: "Task ID",
            res: task.task_id
        },
        ref_id: {
            title: "Reference ID",
            res: task.ref_id
        },
        lang: {
            title: "Language",
            res: task.lang
        },
        queue: {
            title: "Queue",
            res: task.queue
        },
        status: {
            title :"Status",
            res: task.status
        },
        retry_cnt: {
            title: "Retry Count",
            res: task.retry_cnt
        },
        webhook: {
            title: "Webhook",
            res: <a href={task.webhook}>{task.webhook}</a>
        },
        create_dt: {
            title: "Date Created",
            res: task.create_dt.toUTCString()
        },
        
        mod_ts: {
            title: "Last Updated",
            res: task.mod_ts.toUTCString()
        }
    });
}

const getDataFields = (task) => {
    return ({
        date: {
            title: "Date",
            res: new Date(task.data.date).toUTCString()
        },
        lang: {
            title: "Language",
            res: task.data.language
        },
        queue: {
            title: "Queue",
            res: task.data.queue
        },
        "VAD-id": {
            title: "VAD ID",
            res: task.data["VAD-id"]
        },
        fileId: {
            title: "File ID",
            res: task.data.fileId
        },
        "ref-id": {
            title: "Reference ID",
            res: task.data["ref-id"]
        },
        formats: {
            title: "Formats",
            res: task.data.formats ? task.data.formats.join(", "): ""
        },
        webhook: {
            title: "Webhook",
            res: <a href={task.data.webhook}>{task.data.webhook}</a>
        },
        filename: {
            title: "File Name",
            res: task.data.filename
        },
        sampling: {
            title: "Sampling Rate",
            res: task.data.sampling
        }
    });
}

const Task = (props) => {
    let { taskID } = useParams();
    taskID = parseInt(taskID);

    const classes = useStyles();

    const [task, setTask] = useState({});
    const [inputtaskID, setInputtaskID] = useState(taskID);
    const [error, setError] = useState(false);
    const [retrySnackOpen, setRetrySnackOpen] = useState([false, ""]);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        let automaticUpdates = null;
        if(!taskID) {
            fetchLatestTaskID().then((latestTaskID) => {
                setInputtaskID(latestTaskID);
                props.updateTaskUrl(latestTaskID);
            })
        } else {
            fetchTask(taskID).then((task) => {
                setTask(task);
                setError(false);
            }).catch(() => setError(true));
            automaticUpdates = setInterval(() => {
                fetchTask(taskID).then((task) => {
                    setTask(task);
                    setError(false);
                }).catch(() => setError(true));
            }, 10000);
        }
        return () => clearInterval(automaticUpdates);
    }, [error, taskID, props, refresh]);

    const handleRetry = async (task_id) => {
        try {
            let url = taskControllerApiHost + 'tasks/' + task_id + '/actions';
            await axios.post(url, {'type': 'retry'});
            setRetrySnackOpen([true, "Submitted Task "+task_id+" for retrying"]);
        } catch(err) {
            console.log(err);
            setRetrySnackOpen([true, "Failed to submit Task "+task_id+" for retrying"]);
        }
    }

    const handleRetrySnackClose = () => {
        setRetrySnackOpen([false, ""]);
    }

    let fields = {};
    let dataFields = {};

    if(Object.keys(task).length > 0) {
        fields = getFields(task)
        dataFields = getDataFields(task);

        if(task.status === "FAILED") {
            fields.worker = {
                title: "Worker",
                res: task.worker
            }
            fields.err_code = {
                title :"Error Code",
                res: task.err_code
            }
            fields.retry = {
                title: "Retry",
                res: <IconButton aria-label="Retry" size="small" style={{margin: 0}} onClick={() => handleRetry(task.task_id)}><ReplayIcon /></IconButton>
            }
        } 
        else if(task.status === "PROCESSING") {
            fields.worker = {
                title: "Worker",
                res: task.worker
            }
            fields.progress = {
                title: "Progress",
                res: task.progress
            }
            fields.retry = {
                title: "Retry",
                res: <IconButton aria-label="Retry" size="small" onClick={() => handleRetry(task.task_id)}><ReplayIcon /></IconButton>
            }
        }
        else if(task.status === "SUCCESSFUL") {
            fields.process_dt = {
                title: "Processing Finished",
                res: task.process_dt === null ? null : task.process_dt.toUTCString()
            }
            fields.proc_time = {
                title: "Processing Time",
                res: displayDuration(task.proc_time)
            }
            fields.progress = {
                title: "Progress",
                res: task.progress
            }
            fields.worker = {
                title: "Worker",
                res: task.worker
            }
            fields.result = {
                title: "Transcript",
                res: (<a href={task.result["cloud-link"]}>
                        <IconButton aria-label="download-transcript" size="small"><DescriptionIcon /></IconButton>
                    </a>)
            };
            dataFields.recording = {
                title: "Recording",
                res: (<a href={task.data["cloud-link"]} target="_blank" rel="noopener noreferrer">
                        <IconButton aria-label="download-recording" size="small"><RecordVoiceOverIcon /></IconButton>
                    </a>)
            };
        }
    }
    
    return (
        <React.Fragment>
            <Typography variant='h5'>
                <strong>Task</strong><IconButton onClick={() => setRefresh(!refresh)}><RefreshIcon /></IconButton>
            </Typography>
            <br />
            <ErrorHandler>
                <FormControl className={classes.formControl}>
                    <TextField 
                        type="number" 
                        label="Task ID"
                        value={inputtaskID ? inputtaskID : ""}
                        inputProps={{min: 1}}
                        onChange={(event) => setInputtaskID(event.target.value)}
                    />
                </FormControl>
                <IconButton style={{marginTop: 10}} onClick={() => props.updateTaskUrl(inputtaskID)}><ArrowForwardIcon /></IconButton>
                <br />
                <Paper>
                    {error
                    ?
                    <Box margin={2}>
                        <Typography variant="h6"><strong>Task Not Found</strong></Typography>
                    </Box>
                    :
                    <Box margin={2}> 
                        <Grid container spacing={3}>
                            {Object.keys(fields).map((field) => {
                                return (
                                    <Grid item key={field}>
                                        <Card variant="outlined">
                                            { (field === "result" || field === "retry")
                                            ? <CardContent style={{padding: 15}}>
                                                <strong>{fields[field].title}: </strong>
                                                {fields[field].res}
                                            </CardContent>
                                            : <CardContent>
                                                <strong>{fields[field].title}: </strong>
                                                {fields[field].res}
                                            </CardContent>
                                            }
                                        </Card>
                                    </Grid>
                                )
                            })}
                        </Grid>
                        <br />
                        <Paper variant="outlined">
                            <Box margin={2}>
                                <Typography variant="h6">
                                    Data
                                </Typography>
                                <br />
                                <Grid container spacing={3}>
                                    {Object.keys(dataFields).map((field) => {
                                        return (
                                            <Grid item key={field}>
                                                <Card variant="outlined">
                                                { field === "recording"
                                                ? <CardContent style={{padding: 15}}>
                                                    <strong>{dataFields[field].title}: </strong>
                                                    {dataFields[field].res}
                                                </CardContent>
                                                : <CardContent>
                                                    <strong>{dataFields[field].title}: </strong>
                                                    {dataFields[field].res}
                                                </CardContent>
                                                }
                                                </Card>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Box>
                        </Paper>
                        <br />
                    </Box>
                    }
                </Paper>
                <Snackbar
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    }}
                    open={retrySnackOpen[0]}
                    autoHideDuration={5000}
                    onClose={handleRetrySnackClose}
                    message={retrySnackOpen[1]}
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleRetrySnackClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                />
            </ErrorHandler>
        </React.Fragment>
    );
}

export default Task;