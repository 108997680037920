import axios from 'axios';
import { taskControllerApiHost } from './utils';


export const fetchQueueList = async() => {
    // fetch queue list from /queues 
    const queuesUrl = taskControllerApiHost + "queues";
    let queueResponse = await fetch(queuesUrl);
    let { queues } = await queueResponse.json();
    queues = queues.map((queue) => queue.name);
    queues[queues.indexOf("")] = "--";
    return queues;
}

export const retryTask = async (task_id) => {
    try {
        let url = taskControllerApiHost + 'tasks/' + task_id + '/actions';
        await axios.post(url, {'type': 'retry'});
    } catch(err) {
        console.log(err);
    }
}


export const languages = ['english', 'mandarin', 'malay', 'english-mandarin', 'english-malay'];

export const samplingRates = ['8khz', '16khz'];

const statuses = ['PENDING', 'PROCESSING', 'SUCCESS', 'FAIL']

const workerStatuses = ['IDLE', 'PROCESSING', 'COMPLETED']


export const createTaskQueryObject = (
    sortOrder,
    limit, 
    page, 
    queryFilters,
    queueVal,
    langVal,
    samplingVal,
    refVal,
    createFromVal,
    createToVal
) => {
    let queryObject = {
        '_sort_key': sortOrder,
        '_limit': limit,
        '_page': page
    };
    for(let queryFilter of Object.keys(queryFilters)) {
        queryObject[queryFilter] = queryFilters[queryFilter];
    }
    let arr = [
        ['queue', queueVal],
        ['lang', langVal],
        ['sampling', samplingVal],
        ['ref_id', refVal],
        ['date_start', createFromVal],
        ['date_end', createToVal]
    ];
    for(let elem of arr) {
        if(elem[1] !== "") {
            if(elem[0] === 'queue' && elem[1] === "--") elem[1] = "";
            queryObject[elem[0]] = elem[1];
        }
    }
    return queryObject;
}


export const fetchTaskListAndPrepare = async (queryObject) => {

    const constructUrl = () => {
        let url = taskControllerApiHost + 'tasks?'
        let queries = Object.keys(queryObject).map((query) => `${query}=${queryObject[query]}`).join('&');
        return url + queries;
    }
    // fetch task list
    let url = constructUrl()
    const taskListFetch = await fetch(url);
    let taskListJSON = await taskListFetch.json();
    let { tasks } = taskListJSON;
    
    tasks.forEach((task) => {
        task.status = statuses[task.status]; // store status label instead of status id
        task.create_dt = new Date(task.create_dt + 'Z'); // store Date object instead of string
        task.mod_ts = new Date(task.mod_ts + 'Z');
        task.process_dt = task.process_dt === null ? null : new Date(task.process_dt + 'Z');
        task.proc_time = task.process_dt === null ? null : task.mod_ts - task.process_dt; // store duration between mod_ts and process_dt in ms
    });

    return ({
        meta: taskListJSON['_meta'],
        tasks: tasks,
    });

}


export const createWorkerQueryObject = (
    sortKey,
    limit, 
    page, 
    queueVal,
    langVal,
    workerStatusVal,
    activeVal,
    startDateVal,
    endDateVal
) => {
    let queryObject = {
        '_sort_key': sortKey,
        '_limit': limit,
        '_page': page
    };
    let arr = [
        ['queue', queueVal],
        ['lang', langVal],
        ['worker_status', workerStatusVal],
        ['worker_active', activeVal],
        ['date_start', startDateVal],
        ['date_end', endDateVal]
    ];
    for(let elem of arr) {
        if(elem[1] !== "") {
            if(elem[0] === 'queue' && elem[1] === "--") elem[1] = "";
            queryObject[elem[0]] = elem[1];
        }
    }
    return queryObject;
}


export const fetchWorkerListAndPrepare = async (queryObject) => {

    const constructUrl = () => {
        let url = taskControllerApiHost + 'workers?'
        let queries = Object.keys(queryObject).map((query) => `${query}=${queryObject[query]}`).join('&');
        return url + queries;
    }
    // fetch worker list
    let url = constructUrl()
    const workerListFetch = await fetch(url);
    let workerListJSON = await workerListFetch.json();
    let { workers } = workerListJSON;
    
    workers.forEach((worker) => {
        worker.task_id = worker.task_id === 0 ? null : worker.task_id;
        worker.worker_status = workerStatuses[worker.worker_status]; // store status label instead of status id
        worker.last_online_ts = new Date(worker.last_online_ts + 'Z'); // store Date object instead of string
    });

    return ({
        meta: workerListJSON['_meta'],
        workers: workers,
    });

}


export const createTasksByWorkerQueryObject = (
    sortKey,
    limit, 
    page,
    workerVal,
    taskStatusVal,
    queueVal,
    langVal,
    startDateVal,
    endDateVal
) => {
    let queryObject = {
        '_sort_key': sortKey,
        '_limit': limit,
        '_page': page
    };
    let arr = [
        ['worker', workerVal],
        ['status', taskStatusVal],
        ['queue', queueVal],
        ['lang', langVal],
        ['date_start', startDateVal],
        ['date_end', endDateVal]
    ];
    for(let elem of arr) {
        if(elem[1] !== "") {
            if(elem[0] === 'queue' && elem[1] === "--") elem[1] = "";
            queryObject[elem[0]] = elem[1];
        }
    }
    return queryObject;
}


export const fetchTasksByWorkerAndPrepare = async (queryObject) => {

    const constructUrl = () => {
        let url = taskControllerApiHost + 'workers/tasks?'
        let queries = Object.keys(queryObject).map((query) => `${query}=${queryObject[query]}`).join('&');
        return url + queries;
    }
    // fetch worker list
    let url = constructUrl()
    const tasksByWorkerFetch = await fetch(url);
    let tasksByWorkerJSON = await tasksByWorkerFetch.json();
    let { tasks_by_worker } = tasksByWorkerJSON;
    const tasksByWorker = tasks_by_worker;

    tasksByWorker.forEach((obj) => {
        obj.tasks.forEach((task) => {
            task.status = statuses[task.status]; // store status label instead of status id
            task.create_dt = new Date(task.create_dt + 'Z'); // store Date object instead of string
            task.mod_ts = new Date(task.mod_ts + 'Z');
        })
    });

    return ({
        meta: tasksByWorkerJSON['_meta'],
        tasksByWorker: tasksByWorker,
    });

}


export const fetchOverallStatusReport = async () => { // fetch overall workload data
        
    const url = taskControllerApiHost + "reports/status";
    const report = await fetch(url);
    const reportJSON = await report.json();
    const totalStatusReport = reportJSON["app_status"];

    const totalUrl = taskControllerApiHost + "tasks?_limit=0"; // fetch total number of tasks
    const totalTasks = await fetch(totalUrl);
    const totalTasksJSON = await totalTasks.json();
    const totalCount = totalTasksJSON["_meta"].total;

    const successCount = totalCount - (totalStatusReport["task_fail_cnt"] + totalStatusReport["task_processing_cnt"] + totalStatusReport["task_pending_cnt"]);

    totalStatusReport["task_success_cnt"] = successCount;
    totalStatusReport["task_total_cnt"] = totalCount;

    return totalStatusReport;
}


// API calls for Overview
export const fetchCurrentStatusReport = async (time, queue) => {
    const getDateString = (date) => {
        return date.toISOString().split('T')[0];
    }

    let startDate = null;
    let endDate = null;

    switch(time) { // calculate start and end dates for each select value
        default:
            break;
        case "Last 24 hours":
            endDate = getDateString(new Date());
            startDate = new Date();
            startDate.setDate(startDate.getDate() - 1);
            startDate = getDateString(startDate);
            break;
        case "Last Week":
            endDate = getDateString(new Date());
            startDate = new Date();
            startDate.setDate(startDate.getDate() - 7);
            startDate = getDateString(startDate);
            break;
        case "Last Month":
            endDate = getDateString(new Date());
            startDate = new Date();
            startDate.setMonth(startDate.getMonth() - 1);
            startDate = getDateString(startDate);
            break;
        case "Last Year":
            endDate = getDateString(new Date());
            startDate = new Date();
            startDate.setUTCFullYear(startDate.getUTCFullYear() - 1);
            startDate = getDateString(startDate);
            break;
        case "All Time":
            endDate = getDateString(new Date());
            startDate = getDateString(new Date(0));
            break;
    }

    let countByStatus = [0, 0, 0, 0];

    // fetch count for each status
    let statusFetchResponses = await Promise.all([0, 1, 2, 3].map((elem) => {
        let url = taskControllerApiHost + "tasks?_limit=0";
        url += "&status=" + String(elem);
        url += "&date_start=" + startDate;
        url += "&date_end=" + endDate;
        if(queue !== 'All') {
            url += "&queue=" + queue;
        }
        return fetch(url);
    }));
    let statusFetchJSONs = await Promise.all(statusFetchResponses.map((elem) => elem.json()));
    for(let i = 0; i < 4; i++) {
        countByStatus[i] = statusFetchJSONs[i]['_meta'].total
    }

    // fetch queue list from /queues 
    const queuesUrl = taskControllerApiHost + "queues";
    let queueResponse = await fetch(queuesUrl);
    let { queues } = await queueResponse.json();
    queues = queues.map((queue) => queue.name);
    queues.push('All')

    return [{
        task_pending_cnt: countByStatus[0],
        task_processing_cnt: countByStatus[1],
        task_success_cnt: countByStatus[2],
        task_fail_cnt: countByStatus[3],
        task_total_cnt: countByStatus.reduce((num1, num2) => num1 + num2),
    }, queues]
}
