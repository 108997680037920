// some variables used by multiple components

//export const taskControllerApiHost = process.env.REACT_APP_TASK_CONTROLLER_API_HOST.endsWith("/")
//                                      ? process.env.REACT_APP_TASK_CONTROLLER_API_HOST
//                                      : process.env.REACT_APP_TASK_CONTROLLER_API_HOST + "/";

export const taskControllerApiHost = "https://decoding-tasks.speechlab.sg/";

//export const dashboardUrl = process.env.REACT_APP_DASHBOARD_URL.endsWith("/")
//                              ? process.env.REACT_APP_DASHBOARD_URL
//                              : process.env.REACT_APP_DASHBOARD_URL + "/";

export const dashboardUrl = "https://task-dashboard.speechlab.sg/";

//export const gatewayHost = process.env.REACT_APP_GATEWAY_HOST.endsWith("/")
//                              ? process.env.REACT_APP_GATEWAY_HOST
//                              : process.env.REACT_APP_GATEWAY_HOST + "/";

export const gatewayHost = "https://gateway.speechlab.sg/";

