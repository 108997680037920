import React from 'react'
import { TaskTable } from './TaskTable/TaskTable'

const SuccessfulTasks = () => {
    return (
        <TaskTable 
            title={'Successful Tasks'} 
            optionalCols = {{'worker': false, 'proc_time': true, 'dl_transcript': true}}
            queryFilters={{'status': 2}}
            automaticUpdates={false}
        />
    );
}

export default SuccessfulTasks