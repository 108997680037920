import React from 'react'
import { TaskTable } from './TaskTable/TaskTable'

const ProcessingTasks = () => {
    return (
        <TaskTable 
            title={'Processing Tasks'} 
            optionalCols = {{'worker': false, 'progress': true, 'retry': true}}
            queryFilters={{'status': 1}}
            automaticUpdates={true}
        />
    );
}

export default ProcessingTasks