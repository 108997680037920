import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { InputLabel, FormControl, Select, MenuItem } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { fetchOverallStatusReport, fetchCurrentStatusReport } from '../api';
import ErrorHandler from '../ErrorHandler';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh'; 

const useStyles = makeStyles((theme) => ({
    card: {
      minWidth: 240,
    },
    workerCard: {
        minWidth: 240,
        backgroundColor:"#f0f0f0"
    },
    moreCard: {
        backgroundColor:"#f5f5f5"
    },
    cardcontent: {
        paddingLeft: 40,
        paddingRight: 40
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 160,
    }
}));

export const Overview = () => {
    const classes = useStyles();

    const [overallWorkload, setOverallWorkload] = useState({});
    const [queueList, setQueueList] = useState([]);
    const [queueVal, setQueueVal] = useState("All");
    const [timeVal, setTimeVal] = useState("Last Week");
    const [currentWorkload, setCurrentWorkload] = useState({});
    const [refresh, setRefresh] = useState(false);
    const timeList = [
        "Last 24 hours",
        "Last Week",
        "Last Month",
        "Last Year",
        "All Time"
    ]
    
    useEffect(() => {
        fetchOverallStatusReport().then((obj) => {
            setOverallWorkload(obj);
        });
        fetchCurrentStatusReport(timeVal, queueVal).then((arr) => {
            setCurrentWorkload(arr[0]);
            setQueueList(arr[1]);
        });
        let autoUpdates = setInterval(() => {
            fetchOverallStatusReport().then((obj) => {
                setOverallWorkload(obj);
            });
            fetchCurrentStatusReport(timeVal, queueVal).then((arr) => {
                setCurrentWorkload(arr[0]);
                setQueueList(arr[1]);
            });
        }, 10000);
        
        return () => clearInterval(autoUpdates);
    }, [timeVal, queueVal, refresh])

    return (
        <Container maxWidth={false}>
            <Typography variant='h5'>
                <strong>Overview</strong><IconButton onClick={() => setRefresh(!refresh)}><RefreshIcon /></IconButton>
            </Typography>
            <br />
            <ErrorHandler>
                <Divider />
                <br />
                <Typography variant='h6'>
                    <strong>Overall Workload</strong>
                </Typography>
                <br />
                <Grid container spacing={3}>
                    <Grid item md={4} sm={6} xs={12}>
                        <Card className={classes.workerCard}>
                            <CardContent>
                                <Typography>
                                    Available Workers: {overallWorkload["available_worker_cnt"]}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                        <Card className={classes.workerCard}>
                            <CardContent>
                                <Typography>
                                    Workers online (last hour): {overallWorkload["online_worker_cnt"]}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={3}>
                    <Grid item md={4} sm={6} xs={12}>
                        <Card className={classes.card}>
                            <CardContent className={classes.cardcontent}>
                                <Typography variant='h6'>
                                    Failed Tasks
                                </Typography>
                                <br />
                                <Divider />
                                <br />
                                <Typography variant='h6'>
                                    {overallWorkload["task_fail_cnt"]}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                        <Card className={classes.card}>
                            <CardContent className={classes.cardcontent}>
                                <Typography variant='h6'>
                                    Processing Tasks
                                </Typography>
                                <br />
                                <Divider />
                                <br />
                                <Typography variant='h6'>
                                    {overallWorkload["task_processing_cnt"]}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                        <Card className={classes.card}>
                            <CardContent className={classes.cardcontent}>
                                <Typography variant='h6'>
                                    Pending Tasks
                                </Typography>
                                <br />
                                <Divider />
                                <br />
                                <Typography variant='h6'>
                                    {overallWorkload["task_pending_cnt"]}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <br />
                    <Container maxWidth={false}>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>More</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3}>
                                    <Grid item md={6} xs={12}>
                                        <Card className={classes.moreCard}>
                                            <CardContent>
                                                <Typography>
                                                    Successful Tasks: {overallWorkload["task_success_cnt"]}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Card className={classes.moreCard}>
                                            <CardContent>
                                                <Typography>
                                                    Total Tasks: {overallWorkload["task_total_cnt"]}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Container>
                </Grid>
                <br />
                <Divider style={{marginTop: 25}}/>
                <br />
                <Typography variant='h6' >
                    <strong>Current Workload</strong>
                </Typography>
                <FormControl className={classes.formControl}>
                    <InputLabel id="time-select-label">Tasks From</InputLabel>
                    <Select
                        labelId="time-select-label"
                        id="time-select"
                        value={timeVal}
                        onChange={(event) => setTimeVal(event.target.value)}
                        >
                        {timeList.map((time) => <MenuItem value={time} key={time}>{time}</MenuItem>)}
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel id="queue-select-label">Queue</InputLabel>
                    <Select
                        labelId="queue-select-label"
                        id="queue-select"
                        value={queueVal}
                        onChange={(event) => setQueueVal(event.target.value)}
                        >
                        {queueList.map((queue) => <MenuItem value={queue} key={queue}>{queue}</MenuItem>)}
                    </Select>
                </FormControl>
                <div style={{marginTop:10}}/>
                <br />
                <Grid container spacing={3}>
                    <Grid item md={4} sm={6} xs={12}>
                        <Card className={classes.card}>
                            <CardContent className={classes.cardcontent}>
                                <Typography variant='h6'>
                                    Failed Tasks
                                </Typography>
                                <br />
                                <Divider />
                                <br />
                                <Typography variant='h6'>
                                    {currentWorkload["task_fail_cnt"]}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                        <Card className={classes.card}>
                            <CardContent className={classes.cardcontent}>
                                <Typography variant='h6'>
                                    Processing Tasks
                                </Typography>
                                <br />
                                <Divider />
                                <br />
                                <Typography variant='h6'>
                                    {currentWorkload["task_processing_cnt"]}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                        <Card className={classes.card}>
                            <CardContent className={classes.cardcontent}>
                                <Typography variant='h6'>
                                    Pending Tasks
                                </Typography>
                                <br />
                                <Divider />
                                <br />
                                <Typography variant='h6'>
                                    {currentWorkload["task_pending_cnt"]}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <br />
                    <Container maxWidth={false}>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>More</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3}>
                                    <Grid item md={6} xs={12}>
                                        <Card className={classes.moreCard}>
                                            <CardContent>
                                                <Typography>
                                                    Successful Tasks: {currentWorkload["task_success_cnt"]}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Card className={classes.moreCard}>
                                            <CardContent>
                                                <Typography>
                                                    Total Tasks: {currentWorkload["task_total_cnt"]}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Container>
                </Grid>
            </ErrorHandler>
        </Container>
    )
}